@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply font-poppins font-normal;
    }

    h6,
    p {
        @apply font-inter text-sm;
    }

    h1 {
        @apply text-5xl font-semibold;
    }

    h2 {
        @apply text-4xl font-semibold;
    }

    h3 {
        @apply text-2xl font-semibold;
    }

    h4 {
        @apply text-lg font-semibold;
    }

    h5 {
        @apply text-lg font-bold;
    }

    h6 {
        @apply font-bold;
    }

    p {
        @apply font-normal;
    }
}

@layer components {
    .typography-h1 {
        @apply font-poppins text-5xl font-semibold;
    }

    .typography-h2 {
        @apply font-poppins text-4xl font-semibold;
    }

    .typography-h3 {
        @apply font-poppins text-2xl font-semibold;
    }

    .typography-h4 {
        @apply font-poppins text-lg font-semibold;
    }

    .typography-h5 {
        @apply font-poppins text-lg font-bold;
    }

    .typography-h6 {
        @apply font-inter text-sm font-bold;
    }

    .typography-p {
        @apply font-inter text-sm font-normal;
    }

    .auth-screen {
        @apply flex min-h-screen w-full lg:grid lg:grid-cols-2;
    }

    .auth-screen .content {
        @apply mx-auto flex min-w-80 max-w-[601px] flex-col justify-between px-6 py-8 md:px-8 lg:px-10 xl:px-16;
    }

    .auth-social-button {
        @apply flex w-full items-center gap-4 rounded-full bg-shark-950 px-10 py-4 font-poppins text-sm font-bold text-white hover:bg-shark-900;
    }

    .auth-bg-gradient {
        @apply bg-gradient bg-cover bg-no-repeat;
    }

    .search-banner-content {
        @apply px-8 pt-8;
    }

    .search-banner-content > .search-banner {
        @apply relative flex h-full w-full items-end justify-between gap-4 overflow-hidden rounded-xl px-4 hover:bg-gray-600/5;

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: inherit;
            padding: 1px;
            background: linear-gradient(90deg, #ffd6e8, #ff82dc, #ff2bff);
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
        }
    }

    .search-banner-content > .search-banner > div {
        @apply flex flex-col items-start justify-between gap-3 whitespace-nowrap py-4;
    }

    .search-banner-content > .search-banner > div > div > h4 {
        @apply font-inter text-base font-semibold leading-[1.125rem] text-white;
    }

    .search-banner-content > .search-banner > div > div > p {
        @apply font-inter text-sm font-normal leading-[1.125rem] text-white;
    }

    .search-banner-content > .search-banner > div > a {
        @apply z-20 cursor-pointer rounded-full bg-[#FF2BFF] px-4 py-2 font-inter text-sm font-bold leading-[0.875rem] text-white;
    }

    .search-banner-content > .search-banner > svg {
        @apply flex;
    }

    .premium-banner-gradient {
        @apply bg-premium-banner-gradient bg-cover bg-center bg-no-repeat;
    }

    .float-banner {
        @apply flex h-full max-h-0 w-full justify-center overflow-hidden px-8 pt-8 opacity-0 transition-all duration-[3000ms] ease-in-out;
    }

    .float-banner.visible {
        @apply max-h-96 opacity-100;
    }

    .float-banner.draft-available {
        @apply pt-0;
    }

    .float-banner > button {
        @apply premium-banner-gradient w-full items-center rounded-xl p-1 text-shark-950 duration-[3000ms] ease-linear hover:brightness-110;
    }

    .float-banner .content {
        @apply flex items-center justify-between;
    }

    .float-banner .content > .text-container {
        @apply flex items-center gap-3.5;
    }

    .float-banner .content > .text-container > .text-content {
        @apply flex flex-col items-start;
    }

    .float-banner .content > .text-container > .text-content > h4 {
        @apply typography-h4;
    }

    .float-banner .content > .text-container > .text-content > p {
        @apply typography-p;
    }

    .float-banner .content > .text-container > .svg-gift {
        @apply flex h-12 w-12 items-center justify-center rounded-[10px] bg-background-100;
    }

    .float-banner .content > .text-container > .svg-gift > svg {
        @apply fill-none stroke-white;
    }

    .float-banner .content .svg-arrow {
        @apply animate-move-right mr-3 stroke-background-100;
    }

    .toggle-switch {
        @apply relative inline-flex items-center;

        input {
            @apply absolute z-[1] h-full w-full cursor-pointer rounded-full opacity-0;
        }

        span {
            @apply relative h-6 w-10 rounded-full bg-shark-950 transition-[background-color,opacity];

            &:after {
                @apply absolute start-[5px] top-[5px] h-3.5 w-3.5 rounded-full bg-white transition-transform content-[''];
            }
        }

        input:checked + span,
        &.checked span {
            @apply bg-blue-700;

            &:after {
                @apply translate-x-4;
            }
        }

        input:disabled + span,
        &.disabled span {
            @apply cursor-default opacity-50;
        }
    }

    .preview {
        padding-bottom: calc(96px + 2.313rem);
    }

    .ksp.preview .content-container {
        min-height: calc(100vh - 96px);
    }

    .preview .footer-signature .float {
        @apply hidden;
    }
}

@layer utilities {
    @keyframes moveRight {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(calc(100% - 22px));
        }
    }

    .animate-move-right {
        animation: moveRight 1s linear infinite;
    }
}

.settings-signature {
    > div {
        @apply flex items-center justify-between;

        > div {
            @apply flex items-center space-x-4;
        }
    }

    &.is-plus {
        > div {
            @apply block;
            > div {
                @apply justify-between;
            }
        }
    }
}
